import React from "react"
import { Link } from "gatsby"

function HelpCard({ name, list }) {
  console.log("list", list)
  return (
    <div className="body-help__link-card card-link">
      <div className="card-link__title">{name}</div>
      <nav className="card-link__body">
        <ul className="card-link__list">
          {list.map(({ name, url }, index) => (
            <li className="card-link__item" key={index}>
              <Link to={url} className="card-link__link">
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default HelpCard
